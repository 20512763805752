import { useState, useEffect } from "react";
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { ReactComponent as NewTag } from "@ugg/shared/assets/svg/new-tag.svg";
import { ReactComponent as BetaTag } from "@ugg/shared/assets/svg/beta-tag.svg";

export interface NavTab {
  path?: string;
  label: string;
  dropdown?: NavTab[];
  activePaths?: string[];
  reset?: boolean;
  queryParams?: URLSearchParams;
  customComponent?: React.ReactNode;
  new?: boolean;
  live?: boolean;
  beta?: boolean;
  soon?: boolean;
}

function NavTab(props: { tab: NavTab }) {
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { tab } = props;

  useEffect(() => {
    setIsDropdownOpen(false);
  }, [location.pathname]);

  const SoonTag = () => {
    return (
      <div className="bg-[#25254B] rounded-[1px] px-[4px] py-[2px] text-[#CDDCFE] text-[10px] font-bold ml-[6px]">SOON™</div>
    );
  };

  const buildDropdown = (dropdownItems: NavTab[]) => {
    if (dropdownItems.length === 0) return null;

    const list = dropdownItems.map((item, index) => {
      const search = item.reset ? "" : item.queryParams ? item.queryParams.toString() : location.search;
      const props = {
        key: index,
        to: { pathname: item.path, search },
        className: "block px-[20px] py-[15px] text-white hover:bg-accent-purple-700",
        onClick: () => setIsDropdownOpen(false),
      };

      return item.customComponent ? (
        <div {...props}>{item.customComponent}</div>
      ) : (
        <NavLink {...props} to={{ pathname: item.path, search: location.search }}>
          {item.label}
          {item.new && <NewTag className="w-[41px] flex-[41px]" />}
          {item.beta && <BetaTag className="w-[41px] flex-[41px]" />}
          {item.live && <img src="https://static.bigbrain.gg/assets/lol/icons/live-game-active.svg" alt="live-icon" />}
          {item.soon && <SoonTag />}
        </NavLink>
      );
    });

    return (
      <div
        className="
          absolute z-[1000] left-0 top-[calc(100%)] min-w-[200px] 
          rounded-[6px] bg-purple-100 shadow-[0_5px_20px_-5px_black]
          overflow-hidden"
      >
        {list}
      </div>
    );
  };

  const isActive = () => {
    let flag = false;
    if (tab.dropdown) {
      flag = tab.dropdown.some((tab) => {
        return `${tab.path}` === location.pathname;
      });
    }

    if (tab.activePaths) {
      flag = tab.activePaths.some((path) => {
        const matches = location.pathname.match(decodeURIComponent(path));
        return matches !== null;
      });
    }

    return flag || (tab.path && location.pathname.match(decodeURIComponent(tab.path)));
  };

  const search = tab.reset ? "" : tab.queryParams ? tab.queryParams.toString() : location.search;
  const pathname = tab.path;

  const tabProps = {
    className: classnames(
      `relative flex items-center content-center w-[100%] h-[100%] font-bold whitespace-nowrap 
      after:absolute after:content=[''] after:bottom-0 after:w-[100%] after:h-[2px] after:rounded-[2px] 
      after:bg-transparent hover:after:bg-accent-blue-400`,
      {
        "!text-accent-blue-400 after:!bg-accent-blue-400": isActive(),
        "!text-[#5F5F7B] hover:after:!bg-transparent cursor-default": tab.soon,
      },
    ),
    to: { pathname, search },
  };

  const TabComponent = ({ children }: { children: React.ReactNode }) =>
    tab.path ? <NavLink {...tabProps}>{children}</NavLink> : <div {...tabProps}>{children}</div>;

  return (
    <div
      className="relative h-[100%] sm:text-[16px] max-sm:text-[14px] hover:cursor-pointer"
      onMouseEnter={() => tab.dropdown && setIsDropdownOpen(true)}
      onMouseLeave={() => tab.dropdown && setIsDropdownOpen(false)}
    >
      <TabComponent>
        <div className="flex items-center w-[100%] h-[100%]">
          {tab.customComponent || tab.label}
          {tab.new && <NewTag className="w-[41px] flex-[41px]" />}
          {tab.beta && <BetaTag className="w-[41px] flex-[41px]" />}
          {tab.live && <img src="https://static.bigbrain.gg/assets/lol/icons/live-game-active.svg" alt="live-icon" />}
          {tab.soon && <SoonTag />}
        </div>
      </TabComponent>
      {isDropdownOpen && buildDropdown(tab.dropdown || [])}
    </div>
  );
}

export function PageNav(props: { tabs: NavTab[]; className?: string }) {
  const { tabs, className } = props;

  // FIX: overflow-x-auto
  return (
    <div className={classnames("relative flex items-center w-[100%] h-[44px] max-sm:w-[100%] max-sm:h-[44px]", className)}>
      <div className="absolute flex items-center h-[100%] sm:gap-[30px] max-sm:gap-[22px]">
        {tabs.map((tab, index) => (
          <NavTab key={index} tab={tab} />
        ))}
      </div>
    </div>
  );
}
